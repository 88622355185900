<mat-toolbar class="bg-white card">
    <div class="w-100 d-flex justify-content-between">
        <div class="d-flex gap-10">
            <button
                data-toggle="minimize"
                mat-icon-button
                class="example-icon"
                aria-label="Example icon-button with menu icon"
            >
                <mat-icon>menu</mat-icon>
            </button>
            <span (click)="goBack()" class="back-span clickable">
                <mat-icon class="icon">chevron_left</mat-icon>
                <span class="value">Back</span>
            </span>
        </div>
        <div class="left-side">
            <div class="icon-house">
                <ng-container *ngFor="let list of menuList">
                    <button
                        *ngIf="list.isExternalLink"
                        (click)="gotoV1(list.slug)"
                        [ngbTooltip]="list.title"
                        mat-icon-button
                        class="menu-icon"
                    >
                        <span class="material-symbols-outlined">{{ list.icon }}</span>
                    </button>
                    <button
                        [ngClass]="{
                            activeDash: getActiveMenu(list.slug),
                        }"
                        *ngIf="!list.isExternalLink"
                        [routerLink]="list.slug"
                        [ngbTooltip]="list.title"
                        mat-icon-button
                        class="menu-icon"
                    >
                        <span class="material-symbols-outlined">{{ list.icon }}</span>
                    </button>
                </ng-container>
            </div>
        </div>
        <div class="right-side">
            <button [matMenuTriggerFor]="notify" mat-icon-button>
                <span
                    [matMenuTriggerFor]="menu"
                    class="material-symbols-outlined"
                    [matBadge]="nCount"
                    matBadgePosition="after"
                    matBadgeColor="warn"
                    >notifications</span
                >
            </button>
            <mat-menu class="mt-3 check" #notify>
                <span *ngIf="!notifications.getValue().length" class="notify-message p-3 bold"
                    >No Notifications</span
                >
                <ng-template [ngIf]="notifications.getValue().length">
                    <div class="w-100" *ngFor="let notify of notifications | async">
                        <div
                            (click)="open(notify)"
                            class="notify-list-item"
                            [class.notView]="!notify.inAppViewed"
                        >
                            <div class="notify-title">
                                {{ notify.inAppData.title }}
                            </div>
                            <div class="notify-title-holder">
                                <span class="notify-message"> {{ notify.inAppData.message }}</span>
                                <span class="notify-time"
                                    >{{ notify.createdOn | date: 'dd/MM/YY h:mm' }}
                                </span>
                            </div>
                        </div>
                        <hr class="hr" />
                    </div>
                    <div class="w-100 d-flex justify-content-end pr-3 pt-3">
                        <span (click)="openNotification()" class="view-more clickable"
                            >View All</span
                        >
                    </div>
                </ng-template>
            </mat-menu>
            <div [matMenuTriggerFor]="menu" class="d-flex clickable">
                <div class="d-flex flex-column pr-2">
                    <span class="name">
                        {{ user.firstName | titlecase }}
                    </span>
                    <span class="role">
                        {{ user.userRole | titlecase }}
                    </span>
                </div>
                <app-profile-image [user]="user"></app-profile-image>
            </div>

            <mat-menu xPosition="before" #menu="matMenu">
                <button routerLink="/app-settings/profile" mat-menu-item="">
                    <mat-icon>account_circle</mat-icon>
                    <span>My Profile</span>
                </button>
                <div style="min-height: 20px !important" mat-menu-item class="d-flex flex-column">
                    <span>Current Company</span>
                </div>
                <div mat-menu-item class="d-flex gap-10">
                    <div
                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            width: 100%;
                        "
                    >
                        <img
                            [src]="getImage(user.companyData)"
                            alt="company-logo"
                            class="mt-2 img"
                        />
                        <span class="companyName">{{ user?.companyData?.companyName }}</span>
                    </div>
                </div>
                <hr />
                <button (click)="gotoV1('settings')" mat-menu-item>
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </button>
                <button (click)="switchCompany($event)" mat-menu-item="">
                    <mat-icon>room_preferences</mat-icon>
                    <span>Change Organization</span>
                </button>
                <button routerLink="/app-settings/company-profile" mat-menu-item="">
                    <mat-icon>store</mat-icon>
                    <span>Company Profile</span>
                </button>
                <button (click)="logout($event)" mat-menu-item>
                    <mat-icon>power_settings_new</mat-icon>
                    <span>Log Out</span>
                </button>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
