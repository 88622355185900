import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from '../../services/general.service';
import { NotificationService } from '../../services/notification.service';
import { AppStore } from 'src/app/app-data';
import { BehaviorSubject } from 'rxjs';
import { Notify } from '../../models/interfaces';

@Component({
    selector: 'app-view-notifications',
    templateUrl: './view-notifications.component.html',
    styleUrls: ['./view-notifications.component.scss'],
})
export class ViewNotificationsComponent implements OnInit {
    notifications = new BehaviorSubject<Notify[]>([]);
    page = 1;
    constructor(
        private notify: NotificationService,
        private appStore: AppStore
    ) {}

    ngOnInit(): void {
        this.appStore.getNotification().subscribe(async (value: any[] = []) => {
            this.notifications.next(value);
        });
    }

    open(data: Notify) {
        this.notify.openNotification(data);
    }

    onScroll() {
        this.notify.fetch({ page: ++this.page });
    }
}
