import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { Company } from 'src/app/shared/models/company';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss', '../../../../formStyle.scss'],
})
export class RegisterComponent implements OnInit {
    next = true;
    back = false;
    model = new Company();
    submitted = false;
    error: string = '';
    showError = false;
    signUpSuccessful = false;
    isLoading = false;
    //Phone number Internationalization
    separateDialCode = false;
    visibilityType = 'password';
    registerError: any;
    countries: any;
    selectedCountry: any;

    constructor(
        private route: Router,
        private genSrv: GeneralService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.getCountries();
    }

    async getCountries() {
        const result = await this.genSrv.getCountries();
        this.countries = result.data.map((item: any) => ({
            code: item.code,
            dial_code: item.dial_code,
        }));
    }

    login() {
        this.route.navigate(['login']);
    }

    resend() {
        const body = {
            userEmail: this.model.userEmail,
        };
        this.authService.resendEmail(body).subscribe((res) => {
            this.genSrv.alertInfo.next({
                text: res.message,
                btnClass: 'success',
                btnIcon: 'alert-info',
                timer: 5000,
            });
        });
    }

    onSubmit(form: NgForm) {
        if (!form.valid) {
            return;
        }
        let payload = {
            ...form.value,
        };

        Object.keys(payload).forEach((key) => {
            if (!payload[key] && payload[key] !== 0) {
                delete payload[key];
            }
        });

        delete payload.sla;
        this.submitted = true;
        this.isLoading = true;

        this.authService.register(payload).subscribe((res) => {
            this.isLoading = false;
            if (res.responseCode === 201) {
                this.signUpSuccessful = true;
                this.back = false;
            } else {
                return;
            }
        });
    }

    nextPage() {
        this.back = true;
        this.next = false;
    }

    backPage() {
        this.back = false;
        this.next = true;
    }

    toggleVisibility() {
        this.visibilityType = this.visibilityType === 'password' ? 'text' : 'password';
    }
}
