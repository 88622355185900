import { Component, OnInit, OnDestroy } from '@angular/core';

import { ALERTBTN } from 'src/app/interfaces/general';
import { GeneralService } from '../../services/general.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
    alertInfo!: ALERTBTN;

    constructor(private genServ: GeneralService) {}

    ngOnInit() {
        this.genServ.alertInfo.subscribe((res: any) => {
            if (res.text) {
                this.alertInfo = res;
                setTimeout(
                    () => {
                        this.alertInfo = { text: '', btnClass: '', btnIcon: '', timer: 10000 };
                    },
                    res.timer > 3000 ? res.timer : 10000
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.genServ.alertInfo.unsubscribe();
    }
}
