import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { AppStore } from 'src/app/app-data';
import { ChatContact } from 'src/app/interfaces/general';
import { ACTIVECHATS, CONTACTS } from 'src/app/shared/models/constants';
import {
    IFeed,
    ISettings,
    apiChatResponse,
    iDirectChatResponseV2,
} from 'src/app/shared/models/interfaces';
import { GeneralService } from 'src/app/shared/services/general.service';
import { environment } from 'src/environments/environment';
import { AppDispatcher } from '../../../app-data/dispatcher/app.dispatcher';
import { ImagePreview } from '../../../interfaces/general';
import { iContentHeader } from '../../../shared/models/contentheader';
import { IFile, iFeedResponse } from '../../../shared/models/interfaces';
import { getVideoCover } from '../../../utils/image';
import { QueryGenerator } from '../../recruitment/shared/helper';
import { ChatSocketService, OnlineUsers } from './chat-socket.service';
import { AbstractControl, FormControl } from '@angular/forms';
dayjs.extend(localizedFormat);

@Injectable({
    providedIn: 'root',
})
export class Chatv2Service {
    isDestroyes$: Subject<boolean> = new Subject();
    baseApi = environment.chatEndpoint;
    contactSearch: BehaviorSubject<string> = new BehaviorSubject('');
    activeChats: BehaviorSubject<ChatContact[]> = new BehaviorSubject(<any>[]);
    fileUploads: BehaviorSubject<any> = new BehaviorSubject({});
    previewImage: Subject<ImagePreview | null> = new Subject();
    clearImageUpload: Subject<string> = new Subject();
    newPost: Subject<IFeed> = new Subject();
    quickReply: Subject<iDirectChatResponseV2 | null> = new Subject();
    quickReplyDate: Subject<string | boolean> = new Subject();
    autoScroll: BehaviorSubject<boolean> = new BehaviorSubject(<boolean>false);
    groupView: BehaviorSubject<string> = new BehaviorSubject('');
    posts$: BehaviorSubject<iFeedResponse[]> = new BehaviorSubject(<any>[]);
    newFile: Subject<iDirectChatResponseV2> = new Subject();
    contentHeader: BehaviorSubject<iContentHeader> = new BehaviorSubject(<any>{
        headerTitle: 'Chat & Announcements',
        actionButton: true,
        breadcrumb: {
            type: '',
            links: [
                {
                    name: 'Feeds',
                    isLink: false,
                },
            ],
        },
    });

    constructor(
        private _http: HttpClient,
        private _dispatcher: AppDispatcher,
        private store: AppStore,
        private sanitizer: DomSanitizer,
        private genServ: GeneralService,
        private appSocket: ChatSocketService
    ) {
        this.handleContacts();
    }

    getItemFromObject(item: any, key: string) {
        return item[key];
    }

    get socket() {
        return this.appSocket;
    }

    getActiveChats(): Observable<ChatContact[]> {
        return new Observable((subcriber) => {
            this.activeChats.asObservable().subscribe((observer: any) => {
                subcriber.next([...observer]);
            });
        });
    }

    getOnlineUsers(): Observable<OnlineUsers[]> {
        return new Observable((subcriber) => {
            this.appSocket.onlineUsers.asObservable().subscribe((observer: any) => {
                subcriber.next(observer);
                subcriber.complete();
            });
        });
    }

    setupChat() {
        this.appSocket.connect();
        this.fetchActiveChats({});
        this.fetchSettings();
    }

    emit({ event, data, callback }: { event: string; data: any; callback?: Function }): void {
        this.appSocket.emit({
            event,
            data,
            callback,
        });
    }

    fetchActiveChats(params: any) {
        const p = Object.assign({}, { ...params });
        this._http
            .get<apiChatResponse>(`${this.baseApi}v2/chats/get-active-chats${QueryGenerator(p)}`, {
                headers: {
                    nospinner: 'true',
                },
            })
            .subscribe(async (response) => {
                this._dispatcher.setActiveChats(response.payload);
                //this.activeChats.next(response.payload)
            });
    }

    async createGroup(data: any) {
        const result = <any>(
            await this._http
                .post<apiChatResponse>(`${this.baseApi}chats/create-group`, data)
                .toPromise()
        );
        if ([200, 201].includes(result?.status)) {
            this.emit({
                event: 'new_group',
                data: {
                    connectionId: result?.payload?._id,
                    members: result.payload.members,
                    companyId: result.payload.companyId,
                    name: result.payload.name,
                },
            });
            this.fetchActiveChats({ useCache: 'false' });
        }
    }

    async createPost(data: any) {
        const result = <any>(
            await this._http.post<apiChatResponse>(`${this.baseApi}feeds`, data).toPromise()
        );
        this.posts$.next([result.payload, ...this.posts$.getValue()]);
        this.genServ.alertInfo.next({
            text: `Post Created`,
            btnClass: 'alert-success',
            btnIcon: 'checklist',
            timer: 5000,
        });
    }

    async getPosts(query: { [key: string]: any }) {
        const result = <any>(
            await this._http
                .get<apiChatResponse>(`${this.baseApi}feeds${QueryGenerator(query)}`)
                .toPromise()
        );
        this.posts$.next([...this.posts$.getValue(), ...result.payload]);
        return result.payload;
    }

    async getPost(id: string) {
        const result = <any>(
            await this._http.get<apiChatResponse>(`${this.baseApi}feeds/${id}`).toPromise()
        );
        return result.payload;
    }

    getTime(date: string) {
        //  const dt = typeof message === 'string' ? message : message?.createdOn;
        if (!date) {
            return '';
        }
        const cdt = dayjs(date);

        const formatUTC = cdt.format('L');
        const currentUTC = dayjs().format('L');
        if (formatUTC === currentUTC) {
            return cdt.format('LT');
        }
        if (formatUTC === dayjs().subtract(1, 'day').format('L')) {
            return `yesterday`;
        }
        return `${dayjs().diff(cdt, 'day')} d`; //cdt.format("L LT")
    }

    sortbyDate(a: any, b: any) {
        if (!a.dateSent) {
            return 1;
        }
        if (!b.dateSent) {
            return -1;
        }
        if (new Date(a.dateSent) < new Date(b.dateSent)) {
            return 1;
        }
        return -1;
    }

    async handleContacts2() {
        await combineLatest([this.activeChats, this.appSocket.onlineUsers])
            .pipe(
                map(([activeChats, onlineUsers]) => {
                    const chatContacts = [...activeChats].map((value: ChatContact) => {
                        return {
                            ...value,
                            name: value.name || `${value.firstName || ''}  ${value.lastName || ''}`,
                            online: false,
                        };
                    });
                    //check online users
                    for (let user of onlineUsers) {
                        const index = chatContacts.findIndex(
                            (value) => value.userId === user.employeeId
                        );
                        if (index > -1) {
                            const value = chatContacts[index];
                            chatContacts[index] = {
                                ...value,
                                connectionId: user.connectionId,
                                online: true,
                            };
                        }
                    }
                    this._dispatcher.setChatContact(chatContacts);
                    return chatContacts;
                })
            )
            .toPromise();
    }

    handleContacts() {
        combineLatest([
            this.store.getActiveChats(),
            this.store.getOnlineUsers(),
            this.store.getUnReadCount(),
        ])
            .pipe(
                map(([activeChats, onlineUsers, unreadCount]) => {
                    const chatContacts = [...activeChats].map((value: ChatContact) => {
                        const userId = value.userId ?? (value._id || '');
                        //check online users
                        const user = onlineUsers.find(
                            (act: any) => act.employeeId === value.userId
                        );
                        return {
                            ...value,
                            userId,
                            unReadCount: unreadCount[userId],
                            name: value.name || `${value.firstName || ''} ${value.lastName || ''}`,
                            online: !!user,
                            connectionId: user?.connectionId,
                        };
                    });
                    return chatContacts;
                })
            )
            .subscribe((value) => this._dispatcher.setChatContact(value));

        //handle incoming messages
        this.appSocket.newMessage.asObservable().subscribe(async (newMessage) => {
            const chatContacts = await this.store
                .getChatContact()
                .pipe(
                    take(1),
                    catchError((err) => {
                        console.log(err);
                        return err;
                    })
                )
                .toPromise();
            const replyTo = chatContacts.find(
                (value: any) => value.userId === newMessage.replyTo.userId
            );
            this.updateLastMessage(newMessage, true, /GROUP/i.test(replyTo?.category));
        });

        this.appSocket.refresh.asObservable().subscribe(async (state) => {
            if (state) {
                this.fetchActiveChats({ useCache: 'false' });
            }
        });
    }

    async uploadFile($event: any, otherData: any = {}, useHeader = true) {
        const { files, name } = $event.target;
        const formData = new FormData();
        const file = files[0];
        formData.append('file', file);
        let headers = {};

        if (useHeader) {
            headers = {
                nospinner: 'true',
            };
        }

        for (let key in otherData) {
            formData.append(key, otherData[key]);
        }
        const [type, format2] = file.type?.split('/');
        const [format] = /(.\w+)$/.exec(file.name) || [];
        let currentUploads = this.fileUploads.getValue();
        if (!currentUploads[name]) {
            currentUploads[name] = {};
        }
        const index = Object.keys(currentUploads[name]).length;
        const urlImg = URL.createObjectURL(file);
        currentUploads[name] = {
            ...currentUploads[name],
            [index]: {
                key: index,
                name: file.name,
                url: type.includes('video') ? await getVideoCover(urlImg) : urlImg,
                thumbnail: type.includes('video') ? await getVideoCover(urlImg) : urlImg,
                type: type,
                format: format || format2,
                isLoading: true,
                completed: false,
            },
        };
        this.fileUploads.next({ ...currentUploads });
        const url = `${this.baseApi}files`;
        let fileResponse = <any>await this._http
            .post<apiChatResponse>(url, formData, {
                headers: {
                    ...headers,
                },
            })
            .toPromise();
        currentUploads = this.fileUploads.getValue();
        if (!currentUploads[name][index]) {
            return;
        }
        currentUploads[name][index] = {
            ...currentUploads[name][index],
            ...(fileResponse?.payload || {}),
            thumbnail: type.includes('video')
                ? await getVideoCover(fileResponse.payload?.secure_url)
                : fileResponse.payload?.secure_url,
            url: fileResponse.payload?.secure_url,
            isLoading: false,
            completed: true,
        };
        this.fileUploads.next({ ...currentUploads });
    }

    getMentionList(allUsers: any) {
        return (text: string) => {
            const regText = new RegExp(text, 'i');
            return allUsers
                .map((value: any) => {
                    let id = value.id || value._id;
                    if (value.id && value._id) {
                        id = /^[0-9]+$/.test(`${value.id}`) ? value._id : value.id;
                    }
                    return {
                        id,
                        name: value.name || `${value.firstName} ${value.lastName}`,
                        email:
                            value.email || value.userEmail || value.employeeEmail || `${value._id}`,
                        showAdmin: value.showAdmin,
                        profileImgUrl: value.profileImgUrl || this.genServ.getEmployeeImage(value),
                        role: value.userRole || value.jobRole?.name || 'No Role',
                    };
                })
                .filter((val: any) => {
                    return regText.test(val.name);
                })
                .splice(0, 5);
        };
    }

    getMentionsFromText(text: string) {
        var oParser = new DOMParser();
        var oDOM = oParser.parseFromString(text, 'text/html');
        const list = oDOM.querySelectorAll('[data-mention-id]');
        let mentions: any[] = [];
        list.forEach((value) => {
            const attrs = value.attributes;
            mentions.push({
                name: attrs.getNamedItem('data-mention-name')?.value,
                id: attrs.getNamedItem('data-mention-id')?.value,
                email: attrs.getNamedItem('data-mention-email')?.value,
            });
        });
        //replace links
        let match =
            text.match(
                /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
            ) || [];
        match.forEach((element: any) => {
            text = text.replaceAll(
                new RegExp(element, 'gi'),
                `<a href='${element}' target='_blank' > ${element} </a>`
            );
        });
        return {
            textMessage: oDOM.body.innerText,
            htmlMessage: text,
            mentions,
        };
    }

    getName(post: any) {
        if (post.createdBy) {
            return post.createdBy.firstName
                ? post.createdBy.firstName + ' ' + post.createdBy.lastName
                : 'User';
        } else {
            return post.firstName ? post.firstName + ' ' + post.lastName : 'User';
        }
    }

    pollVote({ postId = '', answerId = '' }) {
        const url = `${this.baseApi}feeds/poll/vote/${postId}/${answerId}`;
        return this._http.put<apiChatResponse>(url, {}).toPromise();
    }
    sendPostActivity(postId: string, type: string) {
        const url = `${this.baseApi}feeds/${postId}?nospinner=true`;
        return this._http.put<apiChatResponse>(url, { type }).toPromise();
    }
    async fetchComments(postId: string, query = {}) {
        const result = <any>await this._http
            .get<apiChatResponse>(
                `${this.baseApi}v2/feeds/comments/${postId}${QueryGenerator(query)}`,
                {
                    headers: {
                        nospinner: 'true',
                    },
                }
            )
            .toPromise();
        return result.payload;
    }
    createComment(payload: any) {
        const url = `${this.baseApi}v2/feeds/comments?nospinner=true`;
        return this._http.post<apiChatResponse>(url, payload).toPromise();
    }
    async like(post: iFeedResponse, user: any) {
        //do the for fast response
        const likes = post.likes;
        const index = likes.findIndex((value) => value.employeeId === user._id);
        if (index > -1) {
            likes.splice(index, 1);
            post = {
                ...post,
                likes: likes,
            };
        } else {
            likes.push({
                employeeId: user._id,
                date: new Date(),
            });
        }
        const result = <any>await this.sendPostActivity(post._id, 'likes');
        return {
            ...post,
            likes: result.payload.likes,
        };
    }

    async setCategory(payload: any) {
        const result = <any>(
            await this._http
                .post<apiChatResponse>(`${this.baseApi}chats/manage-category`, payload)
                .toPromise()
        );
        return result.payload;
    }

    fetchMessages(replyTo: string, query = {}, hideLoader = false) {
        const httpOptions = hideLoader
            ? {
                  headers: {
                      nospinner: 'true',
                  },
              }
            : {};
        return this._http.get<apiChatResponse>(
            `${this.baseApi}v2/chats/messages/${replyTo}${QueryGenerator(query)}`,
            { ...httpOptions }
        );
        //return result.payload;
    }
    async updateMessages(id: string, payload = {}) {
        return new Promise((resolve, reject) =>
            this.emit({
                event: 'like_chat',
                data: {
                    id,
                    ...payload,
                },
                callback: (data: any) => {
                    resolve(data);
                },
            })
        );
    }

    async download(file: IFile) {
        const res = <any>(
            await this._http.get(file.url, { responseType: 'blob' as 'json' }).toPromise()
        );
        const blob = new Blob([res], { type: file.type });
        const downloadUrl = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${file.name}`;
        link.target = '_blank';
        link.click();
    }
    async updateLastMessage(data: iDirectChatResponseV2, incoming = false, group = false) {
        const replyTo = incoming && !group ? data.chatBy.userId : data.replyTo.userId;
        this.store
            .getActiveChats()
            .pipe(
                take(1),
                map((value: any[]) => {
                    const contactIndex = value.findIndex((value) => {
                        const id = value.userId || value._id;
                        return id === replyTo;
                    });
                    const temp = [...value];
                    if (contactIndex > -1) {
                        const file = data.files[0];
                        temp[contactIndex] = {
                            ...temp[contactIndex],
                            lastMessage: data.textMessage,
                            category:
                                temp[contactIndex].category === CONTACTS
                                    ? ACTIVECHATS
                                    : temp[contactIndex].category,
                            lastMessageFile: !file
                                ? file
                                : /(image|video)/i.test(file.type)
                                  ? 'img'
                                  : 'other',
                            dateSent: data.createdOn,
                        };
                    }
                    return temp;
                }),
                catchError((err: any) => {
                    console.log({ err });
                    return err;
                })
            )
            .subscribe((value: any) => {
                this._dispatcher.setActiveChats([...value]);
            });
        //console.log(list)
    }
    isInViewport(element: HTMLElement, message = '') {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    makeAdmin(id: any, data: any) {
        const url = `${this.baseApi}v2/chats/add-admin/${id}`;
        return this._http.put<apiChatResponse>(url, data).toPromise();
    }

    removeUser(id: any, data: any) {
        const url = `${this.baseApi}v2/chats/exit-group/${id}`;
        return this._http.put<apiChatResponse>(url, data).toPromise();
    }

    addUser(id: any, data: any) {
        const url = `${this.baseApi}v2/chats/add-members-to-group/${id}`;
        return this._http.put<apiChatResponse>(url, data).toPromise();
    }

    getFiles(id: any, query: any) {
        const url = `${this.baseApi}v2/chats/get-files/${id}${QueryGenerator(query)}`;
        return this._http
            .get<apiChatResponse>(url, {
                headers: {
                    nospinner: 'true',
                },
            })
            .toPromise();
    }

    filterEmployees(list: any[]) {
        return this.genServ.filterOnlyValid(list);
    }
    editGroup(data: any, groupId: string) {
        const url = `${this.baseApi}chats/edit-group/${groupId}`;
        return this._http.put<apiChatResponse>(url, data).toPromise();
    }

    async exitGroup(id: string, data: any) {
        const url = `${this.baseApi}v2/chats/exit-group/${id}`;
        await this._http.put<apiChatResponse>(url, data).toPromise();
        this.fetchActiveChats({ useCache: 'false' });
    }
    createSettings(body: ISettings) {
        const url = `${this.baseApi}settings`;
        return this._http.post<apiChatResponse>(url, body).toPromise();
    }
    fetchSettings() {
        return this._http
            .get<apiChatResponse>(`${this.baseApi}settings`, {
                headers: {
                    nospinner: 'true',
                },
            })
            .pipe(take(1))
            .subscribe((response) => {
                const payload = response.payload;
                this._dispatcher.setChatSettings(payload);
            });
    }
    async deletePost({ postId = '' }) {
        const url = `${this.baseApi}feeds/${postId}`;
        await this._http.delete<apiChatResponse>(url, {}).toPromise();
        const post = this.posts$.getValue();
        const i = post.findIndex((value) => value._id === postId);
        if (i > -1) {
            post.splice(i, 1);
        }
        this.posts$.next([...post]);
        this.genServ.alertInfo.next({
            text: `Post Deleted`,
            btnClass: 'alert-success',
            btnIcon: 'checklist',
            timer: 5000,
        });
    }

    alert({ message, error = false }: { message: string; error?: boolean }) {
        this.genServ.alertInfo.next({
            text: message,
            btnClass: error ? 'alert-danger' : 'alert-success',
            btnIcon: error ? 'error' : 'checklist',
            timer: 5000,
        });
    }

    requiredTrim(control: AbstractControl) {
        const val = typeof control.value === 'string' ? control.value.trim() : control.value;
        if (!val) {
            return {
                required: true,
            };
        }
        return null;
    }
}
