export const enums = {
    SALARY_SCHEME: {
        HEALTH_INSURANCES: {
            NHF: 2.5,
            NHIS: 5,
            NSITF: 1,
        },
        ITEMS: [
            {
                name: 'Basic',
                label: 'Basic',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['gross'],
                schedule: null,
            },
            {
                name: 'Transport',
                label: 'Transport',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['basic', 'gross'],
                schedule: null,
            },
            {
                name: 'Housing',
                label: 'Housing',
                value: 0,
                payrollBudgetId: null,
                isOptional: false,
                type: ['basic', 'gross'],
                schedule: null,
            },
            {
                name: 'Feeding',
                label: 'Feeding',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic'],
                schedule: null,
            },
            {
                name: 'Leave',
                label: 'Leave',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                schedule: 'annually',
            },
            {
                name: 'Medical',
                label: 'Medical',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                schedule: 'annually',
            },
            {
                name: '',
                label: 'OtherAllowance',
                value: 0,
                payrollBudgetId: null,
                isOptional: true,
                type: ['basic', 'gross'],
                schedule: 'annually',
            },
        ],
        PENSION_TYPES: [
            {
                title: '10% : 8% Contribution',
                employer: 10,
                employee: 8,
            },
            {
                title: "20% Employer's Contribution",
                employer: 20,
                employee: 0,
            },
        ],
    },
    YEARS: [2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030],
    MONTHS: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    DAYS: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    PAYROLL_SPLIT_SECTIONS: [
        {
            number: 1,
            batchTitle: 'Batch A',
            percentageTitle: 'First Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
        {
            number: 2,
            batchTitle: 'Batch B',
            percentageTitle: 'Second Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
        {
            number: 3,
            batchTitle: 'Batch C',
            percentageTitle: 'Third Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
        {
            number: 4,
            batchTitle: 'Batch D',
            percentageTitle: 'Fourth Payment',
            payslips: [],
            dataTable: [],
            selectedPayslips: [],
            paymentDate_: [],
            paymentDate: null,
            percentage: null,
            totalPayslipsNet: 0,
        },
    ],
    PAYROLL_WORKFLOW: ['CREATE_PAYROLL', 'REVIEW_PAYROLL', 'APPROVE_PAYROLL'],
    PAYROLL: {
        STATUS: {
            ACTIVE: 1,
            REVIEWED: 2,
            APPROVED: 3,
            REJECTED: 4,
        },
        STATUS_: {
            1: 'Active',
            2: 'Reviewed',
            3: 'Approved',
            4: 'Rejected',
        },
        PAYMENT_STATUS: {
            NOT_PAID: 0,
            PAID: 1,
            PROCESSED: 2,
        },
        PAYMENT_STATUS_: {
            0: 'Not Paid',
            1: 'Paid',
            2: 'Processed',
        },
        WORKFLOW: ['CREATE_PAYROLL', 'REVIEW_PAYROLL', 'APPROVE_PAYROLL'],
        WORKFLOW_: {
            CREATE_PAYROLL: 'CREATE PAYROLL',
            REVIEW_PAYROLL: 'REVIEW PAYROLL',
            APPROVE_PAYROLL: 'APPROVE PAYROLL/PAYMENT',
        },
    },
    PAYROL_COLUMN: [
        { value: 'Branch' },
        // { value: 'Department' }
    ],
    WALLET: {
        STATUS: {
            0: 'Debit',
            1: 'Credit',
        },
    },
};

export enum PayrollHistoryTypesEnums {
    'payroll' = 'payroll',
    'health' = 'health',
    'tax' = 'tax',
    'pension' = 'pension',
    'cash_advance' = 'cash-advance',
    'cash_reimbursement' = 'cash-reimbursement',
    'leave_allowance' = 'leave-allowance',
    'allowance' = 'allowance',
    'tax_pension' = 'tax-and-pension',
    '13th_month' = '13th-month',
    'non_payroll' = 'non-payroll',
}
