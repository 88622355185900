<section>
    <div *ngIf="options.showNavigation" class="d-flex justify-content-between mb-2">
        <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
        >
            <i class="fa fa-chevron-left"></i>
        </div>
        <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            (viewDateChange)="closeOpenMonthViewDay('current')"
            [(viewDate)]="viewDate"
        >
            Today
        </div>
        <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()"
        >
            <i class="fa fa-chevron-right"></i>
        </div>
    </div>
    <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="options.events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        [cellTemplate]="monthCellTemplate"
        (beforeViewRender)="beforeMonthViewRender($event)"
    >
    </mwl-calendar-month-view>

    <ng-template #modalContent let-close="close">
        <div class="modal-header">
            <h5 class="modal-title">Event action occurred</h5>
            <button type="button" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                Action:
                <pre>{{ modalData?.action }}</pre>
            </div>
            <div>
                Event:
                <pre>{{ modalData?.event | json }}</pre>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" (click)="close()">OK</button>
        </div>
    </ng-template>

    <ng-template
        #monthCellTemplate
        let-day="day"
        let-openDay="openDay"
        let-locale="locale"
        style="min-height: auto !important"
        let-tooltipPlacement="tooltipPlacement"
        let-highlightDay="highlightDay"
        let-unhighlightDay="unhighlightDay"
        let-eventClicked="eventClicked"
    >
        <ng-template #defaultTooltip>
            <!-- <div class="d-flex flex-column" *ngFor="let items of day.events; let i = index"> -->
            <div class="d-flex flex-column text-white" *ngFor="let text of day.events[0].meta">
                {{ text }}
            </div>
            <!-- <hr *ngIf="day.events.length - 1 != i" /> -->
            <!-- </div> -->
        </ng-template>

        <div
            class="month-cell-fill-height"
            [ngbPopover]="options?.toolTip?.show && day.badgeTotal ? defaultTooltip : null"
            placement="bottom"
            triggers="mouseenter:mouseleave"
        >
            <div class="cal-cell-top">
                <span class="cal-day-number">{{
                    day.date | calendarDate: 'monthViewDayNumber' : locale
                }}</span>
            </div>
            <span class="cal-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
            <div *ngIf="options?.showEventList" class="cal-events">
                <div
                    class="cal-event"
                    *ngFor="let event of day.events"
                    [style.backgroundColor]="event.color.primary"
                    [ngClass]="event?.cssClass"
                    (mouseenter)="highlightDay.emit({ event: event })"
                    (mouseleave)="unhighlightDay.emit({ event: event })"
                    [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip' : event"
                    [tooltipPlacement]="'bottom'"
                    (click)="$event.stopPropagation(); eventClicked.emit({ event: event })"
                ></div>
            </div>
        </div>
    </ng-template>
</section>
