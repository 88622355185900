<ng-container *ngIf="item">
    @if (!multiple) {
        <div class="mention-house d-flex align-items-center p-1">
            <app-profile-image *ngIf="item.email" [user]="item"></app-profile-image>
            <div class="name-house">
                <span
                    class="name"
                    [innerHTML]="
                        item.name ||
                        item.fullName ||
                        item[nameKey] ||
                        (item.firstName || '') + ' ' + (item.lastName || '')
                    "
                ></span>
                <span *ngIf="item.email" class="time" [innerHTML]="item.email"></span>
                <span
                    *ngIf="item.jobRole?.name"
                    class="time"
                    [innerHTML]="item.jobRole?.name"
                ></span>
            </div>
            <hr />
        </div>
    } @else {
        <div class="d-flex w-100 gap-10 align-items-center">
            <p-checkbox [ngModel]="selection.isSelected(item)" [binary]="true"></p-checkbox>
            <div class="mention-house d-flex align-items-center p-1">
                <app-profile-image *ngIf="item.email" [user]="item"></app-profile-image>
                <div class="name-house">
                    <span
                        class="name"
                        [innerHTML]="
                            item.name ||
                            item.fullName ||
                            item[nameKey] ||
                            (item.firstName || '') + ' ' + (item.lastName || '')
                        "
                    >
                    </span>
                    <span *ngIf="item.email" class="time" [innerHTML]="item.email"></span>
                    <span
                        *ngIf="item.jobRole?.name"
                        class="time"
                        [innerHTML]="item.jobRole?.name"
                    ></span>
                </div>
                <hr />
            </div>
        </div>
    }
</ng-container>
