<div class="row">
    <div class="col-md-12">
        <app-content-header [contentHeader]="contentHeader"></app-content-header>
    </div>
</div>

<div>
    <section>
        <div class="w-100">
            <div class="row">
                <div class="col-md-12">
                    <div class="app-card mt-4">
                        <div class="app-card-header chart-header-wrapper">
                            <div class="table-responsive">
                                <table class="table table-bordered text-center align-items-center">
                                    <thead>
                                        <tr>
                                            <th>S/N</th>
                                            <th>Review Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let workflow of approvalList; let index = index"
                                        >
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ retrieveWorkflowTitle(workflow.type) }}</td>
                                            <td>
                                                <a
                                                    class="btn btn-primary"
                                                    (click)="reviewWorkflow(workflow)"
                                                    >View</a
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="row w-100 mt-4" *ngIf="!approvalList?.length">
                                    <div class="col-md-12">
                                        <h3 class="text-center">No Pending Approvals</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
