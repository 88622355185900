// Keep this in sync with the backend
export const WorkflowTypeEnum = {
    EIU1: 'Employee Information Updates',
    LR2: 'Leave Request',
    EES3: 'Employee Employment Status Updates',
    EC4: 'Employee Compensation Updates',
    R5: 'Requisition',
    TR6: 'Training Request',
    EBI7: 'Employee Bank Information Updates',
    JR8: 'Job Request',
    AG9: 'Assessment Goals',
    EX10: 'Employee Final Benefits',
    EX11: 'Employee Termination/Resignation Request',
};
const moneyFormat = (value: any) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(value);
};

export const WorkflowTypeDataMap: any = {
    AG9: [
        { key: 'objective', label: 'Objective' },
        { key: 'description', label: 'Goal Name' },
        { key: 'perspective', label: 'Perspectives' },
        { key: 'weight', label: 'Weight' },
        { key: 'files' },
        { key: 'goalType' },
        { key: 'milestones' },
        { key: 'kpi' },
    ],
    EC4: [
        {
            key: 'annualSalary',
            label: 'Annual Salary',
            format: moneyFormat,
        },
    ],
    EES3: [
        { key: 'termination', label: 'Termination' },
        { key: 'isActive', label: 'Deactivate Employee' },
        {
            key: 'status',
            label: 'Status',
            format: (value: any) => (value ? 'Terminate' : 'Revert-Termination'),
        },
    ],
    EX10: [
        { key: 'balance', label: '' },
        { key: 'payslip', label: '' },
        { key: 'period', label: 'Payment Period' },
        { key: 'startDate', label: 'Start Date' },
        { key: 'endDate', label: 'End Date' },
        { key: 'Basic', format: moneyFormat },
        { key: 'name' },
        { key: 'amount', format: moneyFormat },
        { key: 'benefitName', label: 'Benefit Name' },
        { key: 'Housing', format: moneyFormat },
        { key: 'Transport', format: moneyFormat },
        { key: 'Leave', format: moneyFormat },
        { key: 'Medical', format: moneyFormat },
        { key: 'benefits', label: 'Exit Benefits' },
        { key: 'bonuses' },
        { key: 'deductions' },
        { key: 'net', format: moneyFormat },
        { key: 'OtherAllowance', label: 'Other Allowances' },
        { key: 'allowance', format: moneyFormat },
    ],
    EX11: [
        { key: 'exit', label: '' },
        { key: 'reason', label: '' },
        {
            key: 'lastWorkingDay',
            label: 'Last Working Day',
            format: (value = '') => value?.split('T')[0],
        },
        { key: 'comment', label: 'Comment' },
        { key: 'name', label: 'Name' },
        { key: 'document', label: 'Document' },
    ],
};

// Keep this in sync with the backend
export const WorkflowOptionsEnum: { [key: string]: string } = {
    CompanyRole: 'Company Role',
    ManagerReportsTo: 'Manager (Reports to)',
    ManagersManager: "Manager's Manager",
    SpecificPerson: 'Specific Person',
};

export function flipObject(obj: Record<string, string>): Record<string, string> {
    const flippedObj: Record<string, string> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            flippedObj[obj[key]] = key;
        }
    }
    return flippedObj;
}

export function mapObjectKeyToKey(obj: Record<string, string>): Record<string, string> {
    const mapping: Record<string, string> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            mapping[key] = key;
        }
    }
    return mapping;
}

export function cloneObject(toClone: object): any {
    return Object.assign({}, toClone);
}

export function splitAndAddSpaces(inputString: string) {
    return inputString
        .split(/(?=[A-Z])/)
        .join(' ')
        .trim();
}

export function capitalizeString(inputString: string) {
    if (inputString.length < 0) return inputString;

    return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
}

export function buildWorkflowEmailTable(workflowProcess: any) {
    const WorkflowOptionsKeyEnum = mapObjectKeyToKey(WorkflowOptionsEnum);

    let tableHTML =
        '<table class="tg"> ' +
        '<thead>' +
        '    <tr>' +
        '        <th class="tg-0pky">Approval Type</th>' +
        '        <th class="tg-0pky">Approvers</th>' +
        '        <th class="tg-0pky">Status</th>' +
        '     <th class="tg-0pky" > Comment </th>' +
        '    </tr>' +
        '</thead><tbody>';

    for (let i = 0; i < workflowProcess.length; i++) {
        const element = workflowProcess[i];

        let type = element.type;
        if (type === WorkflowOptionsKeyEnum.CompanyRole) {
            type += `: ${element.roleName}`;
        }
        let status = element.status;
        let approvers = '';

        // Check if "approvers" key exists and build the approvers list
        if (element.approvers && element.approvers.length > 0) {
            approvers = '<ul style="list-style-type:none !important;">';
            for (const approver of element.approvers) {
                approvers += `<li>${approver.name}</li>`;
            }
            approvers += '</ul>';
        } else {
            approvers = '<ul style="list-style-type:none;">Pending Current Approval</ul>';
        }

        // Add a new row to the HTML table
        tableHTML += `<tr><td class="tg-0lax">${splitAndAddSpaces(
            type
        )}</td><td class="tg-0lax">${approvers}</td><td class="tg-0lax">${capitalizeString(
            status
        )}</td>
        <td class="tg-0lax">${element.comment || ''}</td>
        
        </tr>`;
    }

    tableHTML += '</tbody></table>';

    return tableHTML;
}
