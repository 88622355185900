<section class="mt-3">
    <app-filter
        *ngIf="!options.hideFilter"
        (onFilter)="onFilterChange($event)"
        [options]="options?.searchOptions"
    ></app-filter>
    <app-export
        [params]="currentParams"
        *ngIf="options.exportOptions"
        [url]="options.exportOptions?.url || currentUrl"
        [fileName]="options.exportOptions?.fileName || ''"
    ></app-export>
    <div *ngIf="!loading; else loader" class="table-container d-flex flex-column w-100">
        <table
            mat-table
            class="w-100"
            [dataSource]="dataSource"
            (matSortChange)="onSort($event)"
            matSort
        >
            <!-- ID Column -->
            <ng-container matColumnDef="#">
                <th mat-header-cell *matHeaderCellDef>#</th>
                <td
                    mat-cell
                    *matCellDef="let row; let i = index"
                    [routerLink]="options.router ? options.router() : null"
                >
                    {{ i + 1 }}
                </td>
            </ng-container>
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox [checked]="isAllChecked" (change)="handleToggle()"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row; let i = index">
                    <mat-checkbox
                        [checked]="selection.isSelected(row)"
                        (change)="handleCheck(row)"
                    ></mat-checkbox>
                </td>
            </ng-container>
            <ng-container *ngFor="let column of options.columns" [matColumnDef]="column.label">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    [disabled]="column.noSort || options.noSort"
                >
                    <app-data-table-custom
                        [data]="dataSource"
                        *ngIf="column.labelTemplate; else labelText"
                        [template]="column.labelTemplate"
                    ></app-data-table-custom>
                    <ng-template #labelText> {{ column.label }}</ng-template>
                    <i *ngIf="column.label == sortCol" class="fa ml-2 fa-spin fa-spinner"></i>
                </th>
                <td
                    [ngStyle]="column.headerStyle"
                    mat-cell
                    *matCellDef="let row; let i = index"
                    [routerLink]="column.router ? column.router(row) : null"
                >
                    <app-data-table-custom
                        [index]="i"
                        [data]="row"
                        *ngIf="column.template; else text"
                        [template]="column.template"
                    ></app-data-table-custom>
                    <ng-template #text>{{
                        column.getValue ? column.getValue(row) : row[column.key]
                    }}</ng-template>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        </table>
        <div *ngIf="!options.hidePagination" class="d-flex justify-content-end mt-3">
            <app-paginator
                [paginatorSetup]="paginatorSetup"
                (paginateEvent)="handlePaginateEvent($event)"
            ></app-paginator>
        </div>
    </div>
    <ng-template #loader>
        <div class="d-flex justify-content-center align-items-center">
            <div class="table-spinner-wrapper">
                <ng-lottie [options]="loaderOptions"></ng-lottie>
            </div>
        </div>
    </ng-template>
</section>
