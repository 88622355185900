import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApprovalWorkflowService } from '../approval-workflow.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { WorkflowTypeEnum } from '../util';

@Component({
    selector: 'approval-workflow-dashboard',
    templateUrl: './approval-workflow-dashboard.component.html',
    styleUrls: ['./approval-workflow-dashboard.component.scss'],
})
export class ApprovalWorkflowDashboardComponent implements OnInit {
    paginatorSetup: any;
    approvalList: any;
    workType: any = WorkflowTypeEnum;
    contentHeader = {
        headerTitle: 'Approval Workflow',
        actionButton: true,
        breadcrumb: {
            links: [
                {
                    name: 'Categories',
                    isLink: false,
                },
            ],
        },
    };
    constructor(
        private router: Router,
        private genSrv: GeneralService,
        private approvalWorkflowService: ApprovalWorkflowService
    ) {}

    ngOnInit(): void {
        this.getMyWorkflow();
    }

    async getMyWorkflow() {
        this.approvalWorkflowService.getMyWorkflow().subscribe(
            (res) => {
                this.paginatorSetup = res.data.paging;
                this.approvalList = res.data.data;
            },
            (err) => {
                const error = err.details.message || err.message || err.error;

                return this.genSrv.alertInfo.next({
                    text: error,
                    btnClass: 'alert-danger',
                    btnIcon: 'error',
                    timer: 5000,
                });
            }
        );
    }

    retrieveWorkflowTitle(type: any) {
        return this.workType[type] || type;
    }

    reviewWorkflow(workflow: any) {
        const link = this.workType[workflow.type]
            ? '/app-settings/approval-workflow/view/'
            : '/app-settings/approval-workflow/';
        this.router.navigate([link, workflow.link || workflow._id]);
    }
}
