<div class="container-fluid">
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section *ngIf="hmoProvider" class="card p-3 rounded">
        <div class="header py-2 d-flex flex-row justify-content-between align-items-center">
            <h4 class="p-name">{{ hmoProvider.providerName }}</h4>

            <img [src]="hmoProvider.logo" class="provider-logo" alt="" />
        </div>

        <p-accordion [activeIndex]="0">
            <p-accordionTab header="Plans">
                <div class="description">
                    <span class="instructions">Instructions</span>
                    <ul>
                        <li>Click any preferred plan from the options below to get started.</li>
                        <li>From the pop-up select the employees you want to buy for.</li>
                        <li>
                            Close the pop-up and click the buy plan button to continue to payment
                            page.
                        </li>
                    </ul>
                    <span class="note"
                        >Note: You can buy as much plans as you wish by selecting any of the plans
                        and following the prompt. This means you can buy multiple plans at once.
                    </span>
                </div>
                <div class="hmo-plans-wrapper">
                    <ng-container *ngFor="let plan of plans.value; let i = index">
                        <app-hmo-plan
                            [planIndex]="i"
                            [hmoPlan]="plan"
                            [allPlans]="plans.value"
                            (onEmployeeAdded)="employeeAdded($event, i)"
                        ></app-hmo-plan>
                    </ng-container>
                </div>
                <div *ngIf="planBreakdown.body.length" class="d-flex flex-column mb-4">
                    <span class="tr-bd"> Transaction Breakdown </span>
                    <span class="tr-ds"
                        >(Hover on the amount of each plan to see the beneficiaries)</span
                    >
                    <app-table *ngIf="planBreakdown" [tableData]="planBreakdown"></app-table>
                    <div class="d-flex justify-content-center align-items-center p-1">
                        <button (click)="buyPlan()" class="btn btn-primary">
                            Initiate Payment
                        </button>
                    </div>
                </div>
            </p-accordionTab>
            <p-accordionTab header="About">
                <p>
                    {{ hmoProvider.about }}
                </p>
            </p-accordionTab>
            <p-accordionTab header="Network">
                <div class="row mt-3 d-flex flex-column align-items-center">
                    <div class="network">
                        <div
                            class="d-flex flex-column justify-content-center align-items-center"
                            *ngFor="let provider of hmoProvider.network"
                        >
                            <p>{{ provider.name }}</p>
                            <p>{{ provider.address }}</p>
                            <p>{{ provider.phone }}</p>
                        </div>
                    </div>
                    <p>Can't find the specialist you are looking for?</p>
                    <button class="btn btn-primary">Contact {{ hmoProvider.providerName }}</button>
                </div>
            </p-accordionTab>
        </p-accordion>
    </section>
</div>
