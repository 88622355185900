import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AVOIDAPIS } from '../utils/AvoidApisloader';
import { environment } from './../../environments/environment';

import { SpinnerService } from '../services/spinner/spinner.service';
import { GeneralService } from '../shared/services/general.service';

import { SuccessViewComponent } from '../shared/modals/success-view/success-view.component';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
    disabledRoutes: string[] = [];
    isErrorModal = false;
    avoidLoaderApisArr: string[] = AVOIDAPIS;
    errorModal!: any;

    constructor(
        private router: Router,
        private genServ: GeneralService,
        private modalController: NgbModal,
        private spinnerService: SpinnerService
    ) {}

    enableSpinner(url: string, hasNoSpinner: boolean): boolean {
        if (url.indexOf('nospinner=true') !== -1) {
            return false;
        }
        if (hasNoSpinner) {
            return false;
        }
        return !this.disabledRoutes.some((disabledRoute) => disabledRoute === url);
    }

    useApiErrorMessage(url: string) {
        return url.indexOf('apierrresponse=true') !== -1;
    }

    removeAssets(url: string): void {
        if (url.includes('assets')) {
            this.disabledRoutes.push(url);
        }
    }

    avoidApisLoader(url: string): void {
        this.avoidLoaderApisArr.forEach((el) => {
            if (url.includes(el)) {
                this.disabledRoutes.push(url);
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const serializedParams = request.params.toString();
        const urlWithParams = serializedParams ? `${request.url}?${serializedParams}` : request.url;
        const hasNoSpinner = request.headers.has('nospinner');
        const noErrorAlert = request.headers.has('noerroralert');
        this.removeAssets(urlWithParams);
        this.avoidApisLoader(urlWithParams);

        if (this.enableSpinner(urlWithParams, hasNoSpinner)) {
            this.spinnerService.requestStarted(urlWithParams);
        }

        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    if (this.enableSpinner(urlWithParams, hasNoSpinner)) {
                        this.spinnerService.requestEnded(request.url);
                    }
                }
            }),
            catchError((error: HttpErrorResponse) => {
                if (this.enableSpinner(urlWithParams, hasNoSpinner)) {
                    this.spinnerService.requestEnded(urlWithParams);
                }

                const genericErrorMessage =
                    "Sorry, we're unable to complete your request, please try again later";
                const regex = new RegExp(/http/gi);
                const errorMessage: any = {
                    error: '',
                    errorStatus: '',
                    message: genericErrorMessage,
                    details: '',
                };

                if (error.status === 401) {
                    this.handleUnauthenticated();
                    return throwError(error);
                } else if (error.status === 404 || error.status === 500) {
                    errorMessage.message = genericErrorMessage;
                } else if (error.status === 403) {
                    console.log(error);
                    errorMessage.message = 'Forbidden from accessing this resource';
                } else {
                    errorMessage.message = error.error.message;
                }

                if (
                    error.error?.error_description?.match(regex) ||
                    error.error?.statusCode === '404' ||
                    error.error?.statusCode === '500'
                ) {
                    error.error.error_description = genericErrorMessage;
                }

                if (error.error instanceof ErrorEvent) {
                } else {
                    errorMessage.errorStatus = `${error.status}`;
                    errorMessage.details = error.error;
                    errorMessage.message = error.error.message || error.error.error;
                    if (this.useApiErrorMessage(urlWithParams)) {
                        errorMessage.message = error.error.message || 'Error Occurred';
                    }
                }
                if (!noErrorAlert) {
                    this.displayAlert({
                        text: errorMessage.message,
                        btnClass: 'alert-danger',
                        btnIcon: 'error',
                        timer: 10000,
                    });
                }
                return throwError(errorMessage);
            })
        );
    }

    private displayAlert(payload: {
        text: string;
        btnClass: string;
        btnIcon: string;
        timer: number;
    }) {
        const { text, btnClass, btnIcon, timer } = payload;
        return this.genServ.alertInfo.next({
            text,
            btnClass,
            btnIcon,
            timer,
        });
    }

    private handleUnauthenticated() {
        this.genServ.removeAppToken();
        this.router.navigateByUrl(`/login?redirectTo=${window.location.pathname}`);
    }
}
