<section>
    <app-modal-cover [title]="config.title">
        <div class="w-100" [ngClass]="{ 'm-4': !config.html }">
            <div class="d-flex justify-content-center flex-column align-items-center">
                <div class="swal-text" *ngIf="config.text">
                    <div *ngIf="config.svgIcon" class="mb-4" style="margin: 0 200px">
                        <mat-icon [svgIcon]="config.svgIcon" class="icon"></mat-icon>
                    </div>
                    <h4 class="mb-4" style="width: 100%; text-align: center">
                        {{ config.text }}
                    </h4>
                </div>
                <div class="swal-html" *ngIf="config.html" [innerHtml]="config.html"></div>
            </div>
            <div class="row">
                <div class="col-12 d-flex justify-content-center align-items-center mt-2">
                    <button
                        class="btn mr-4"
                        [ngClass]="btn.btnStyle"
                        *ngFor="let btn of config.btns"
                        (click)="handleButton(btn.title)"
                        style="width: 100px"
                    >
                        {{ btn.title }}
                    </button>
                </div>
            </div>
        </div>
    </app-modal-cover>
</section>
