import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { filterObjValue, filterValue, searchOptions } from '../../../interfaces/general';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
    @Input()
    options?: searchOptions[] = [];
    formInput!: UntypedFormGroup;
    @Output()
    onFilter = new EventEmitter();
    valueToTransmit: any = {};
    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        if (!this.options) return;
        const buildForm: any = {};
        for (let items of this.options) {
            let dv = '';

            if (items.type === 'select') {
                const isDefault = items.options?.find((value: any) => value.selected);
                if (isDefault) dv = isDefault.value;
            }
            this.valueToTransmit[items.name] = dv;
            if (items.type === 'compare') {
                buildForm[items.name] = this.fb.group({
                    operator: ['>'],
                    value: [''],
                });
                continue;
            }
            buildForm[items.name] = [dv];
        }
        this.formInput = this.fb.group(buildForm);
    }

    filter() {
        let emitValue: filterValue = {};
        for (let items in this.formInput.value) {
            if (!this.formInput.value[items]) continue;
            const option = this.options?.find((value) => value.name === items);
            if (!option) continue;

            const { transform, type } = option;
            if (type === 'compare' && !(<filterObjValue>this.formInput.value[items])?.value)
                continue;
            emitValue[items] = this.formInput.value[items];
            if (transform) {
                emitValue = <filterValue>{
                    ...emitValue,
                    [items]: transform(emitValue[items]),
                };
            }
        }
        this.onFilter.emit(emitValue);
    }

    onClear() {
        if (!this.options) return;
        for (let items of this.options) {
            let dv = '';
            if (items.type === 'select') {
                const isDefault = items.options?.find((value: any) => value.selected);
                if (isDefault) dv = isDefault.value;
            }
            if (items.type === 'compare') {
                this.formInput.controls[items.name]?.get('operator')?.setValue('>');
                this.formInput.controls[items.name]?.get('value')?.setValue('');
                continue;
            }
            this.formInput.controls[items.name]?.setValue(dv);
        }
        this.filter();
    }

    onChange(formInput: searchOptions) {}
}
