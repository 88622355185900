import { environment } from 'src/environments/environment';
import constants from '../modules/performance/utils/constants';
import urls from '../services/service-list.json';

export default {
    LEARNING: {
        V1: {
            files: environment.learningEndPoint + '/files',
        },
    },
    MX_BE: {
        V1: {
            promotions: {
                getPolicies: environment.myXalaryEndpoint + '/v1/promotion/policies/get',
                getRequest: environment.myXalaryEndpoint + '/v1/promotion/get',
            },
            users: {
                getUsers: environment.myXalaryEndpoint + '/v1/users/listUsers',
                updateUser: environment.myXalaryEndpoint + '/v1/users/updateProfile/',
                deactivateUser: environment.myXalaryEndpoint + '/v1/users/deactivate/',
                activateUser: environment.myXalaryEndpoint + '/v1/users/activate/',
                resetPassword: environment.myXalaryEndpoint + '/v1/users/resetPassword',
                addUser: environment.myXalaryEndpoint + '/v1/users/addUser',
                changePassword: environment.myXalaryEndpoint + '/v1/users/changePassword',
                updateCompanyProfile: environment.myXalaryEndpoint + '/v1/company/updateProfile',
                getLogs: environment.myXalaryEndpoint + '/v1/users/getLogs',
                getProfile: environment.myXalaryEndpoint + '/v1/users/profile',
            },
            employees: {
                getEmployeeCategory:
                    environment.myXalaryEndpoint + '/v1/employees/getEmployeeCategory',
                verifyBankDetails:
                    environment.myXalaryEndpoint + '/v1/employees/bankaccount/resolve',
                getHeadCount: environment.myXalaryEndpoint + '/v1/employees/getEmployeesHeadCount',
            },
            roles: {
                getRoles: environment.myXalaryEndpoint + '/v1/roles/getRoles',
                getPermissions: environment.myXalaryEndpoint + '/v1/roles/getPermissions',
                createRole: environment.myXalaryEndpoint + '/v1/roles/createRole',
                updateRole: environment.myXalaryEndpoint + '/v1/roles/updateRole/',
                deleteRole: environment.myXalaryEndpoint + '/v1/roles/deleteRole/',
            },
            payroll: {
                analytics: environment.myXalaryEndpoint + '/v1/payroll/analytics/get',
                getPayrollReport: environment.myXalaryEndpoint + '/v1/payroll/payroll-report',
                getWorkFlow: environment.myXalaryEndpoint + '/v1/payroll/workflow/get',
                createWorkflow: environment.myXalaryEndpoint + '/v1/payroll/workflow/create',
                updateWorkflow: environment.myXalaryEndpoint + '/v1/payroll/workflow/update/',
                updateSetup: environment.myXalaryEndpoint + '/v1/setup/update',
                getPayrollSetup: environment.myXalaryEndpoint + '/v1/setup/get',
            },
            leave: {
                getAllApplication:
                    environment.myXalaryEndpoint + '/v1/leavemgt/viewAllLeaveApplications',
                analytics: environment.myXalaryEndpoint + '/v1/leave/analytics',
                getLeaveWeeklyReport: environment.myXalaryEndpoint + '/v1/leave/weekly-leave',
                getLeaveUtil: environment.myXalaryEndpoint + '/v1/leave/leave-util',
                getLeaveTypeCounts: environment.myXalaryEndpoint + '/v1/leave/leave-type-count',
            },
            exit: {
                getReasonsCount: environment.myXalaryEndpoint + '/v1/exit/getReasonsCount',
                getPolicies: environment.myXalaryEndpoint + '/v1/exit/policies/get',
                getRequest: environment.myXalaryEndpoint + '/v1/exit/get',
            },
            medical: {
                analytics: environment.myXalaryEndpoint + '/v1/medical/analytics',
            },
            taxandpension: {
                analytics: environment.myXalaryEndpoint + '/v1/taxandpension/analytics',
            },
            utils: {
                getBanks: environment.myXalaryEndpoint + '/v1/util/find-banks',
                getCountries: environment.myXalaryEndpoint + '/v1/util/find-countrys',
                getCities: environment.myXalaryEndpoint + '/v1/util/find-cities',
                getIndustries: environment.myXalaryEndpoint + '/v1/util/find-industrys',
                uploadFile: environment.myXalaryEndpoint + '/v1/util/files',
            },
            attendance: {
                dashboard: environment.myXalaryEndpoint + '/v1/attendance/dashboard',
            },
            billing: {
                setPermission: environment.myXalaryEndpoint + '/v1/billing/permission/set',
                getOTP: environment.myXalaryEndpoint + '/v1/billing/safetoken/get',
                setPin: environment.myXalaryEndpoint + '/v1/billing/pin/set',
                getCompliance: environment.myXalaryEndpoint + '/v1/billing/compliance/get',
                updateCompliance: environment.myXalaryEndpoint + '/v1/billing/compliance/update',
                createBeneficiary: environment.myXalaryEndpoint + '/v1/billing/beneficiary/create',
                getBalance: environment.myXalaryEndpoint + '/v1/billing/wallet/balance/get',
                getWallet: environment.myXalaryEndpoint + '/v1/billing/wallet/get',
                deleteBeneficiary: environment.myXalaryEndpoint + '/v1/billing/beneficiary/delete',
                initiateTransaction: environment.myXalaryEndpoint + '/v1/billing/initiate',
                getVirtualAccounts:
                    environment.myXalaryEndpoint + '/v1/billing/virtual-account/get',
                createVirtualAccounts:
                    environment.myXalaryEndpoint + '/v1/billing/virtual-account/create',
                createBilling: environment.myXalaryEndpoint + '/v1/billing/create',
                transfer: environment.myXalaryEndpoint + '/v1/billing/korapay/transfer',
                getTransaction: environment.myXalaryEndpoint + '/v1/billing/korapay/transaction',
                reportTransaction: environment.myXalaryEndpoint + '/v1/billing/report/transaction',
            },
        },
        V2: {
            billing: {
                updateCompliance: environment.myXalaryEndpoint + '/v2/billing/compliance/update',
                getBeneficiaries: environment.myXalaryEndpoint + '/v2/billing/beneficiary/get',
            },
        },
    },

    REC_BE: {
        V1: {
            jobs: {
                getReport:
                    environment.recruitmentEndpoint + urls.jobOpportunities.getRecruitmentReport,
            },
        },
    },
    PF_BE: {
        V1: {
            main: environment.performanceEndPoint + '/v1',
            recommendations: {
                list:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.RECOMMENDATION +
                    '/list',
            },
            assessmentCycle: {
                list:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.ASSESSMENT_CYCLE +
                    '/list',
                getListForPeriod:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.ASSESSMENT_CYCLE +
                    '/list',
            },
            goals: {
                getAllGoals:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.GOALS +
                    '/get-all-goals/',
            },
            assessments: {
                getListForPeriod:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.ASSESSMENT +
                    '/periods/',
                downloadTemplate:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.ASSESSMENT +
                    '/download-template/',
                uploadTemplate:
                    environment.performanceEndPoint + '/v1/' + constants.MODELS.ASSESSMENT,
                exportAssessment:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.ASSESSMENT +
                    '/export-final-assessment/',
            },
            reports: {
                performanceRating:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.REPORTS +
                    '/get-performance-rating',
                customReport:
                    environment.performanceEndPoint +
                    '/v1/' +
                    constants.MODELS.REPORTS +
                    '/get-custom-report',
            },
            files: environment.performanceEndPoint + '/v1/files',
        },
    },
    KPI_BE: {
        V1: {
            main: environment.kpiEndpoint + '/v1',
            getCostOfWorkForce:
                environment.kpiEndpoint + '/v1' + '/compensation-kpi/cost-of-workforce/',
            getReturnOfInvestment:
                environment.kpiEndpoint + '/v1' + '/compensation-kpi/return-of-investment/',
            getHealthAnalysis: environment.kpiEndpoint + '/v1' + '/compensation-kpi/health-cost/',
            getSCR: environment.kpiEndpoint + '/v1' + '/compensation-kpi/scr/',
            getRevnue: environment.kpiEndpoint + '/v1' + '/compensation-kpi/revenue/',
            getLeaveCount: environment.kpiEndpoint + '/v1' + '/culture-kpi/leave-count/',
            getCultureTrainingCount:
                environment.kpiEndpoint + '/v1' + '/culture-kpi/culture-training-count/',
            getEmployeeMetrics: environment.kpiEndpoint + '/v1' + '/employment-kpi/metrics/',
            getEmployeeTribes: environment.kpiEndpoint + '/v1' + '/employment-kpi/tribes/',
            getTenure: environment.kpiEndpoint + '/v1' + '/employment-kpi/tenure/',
            getAttrition: environment.kpiEndpoint + '/v1' + '/employment-kpi/attrition/',
            getFailedIn90: environment.kpiEndpoint + '/v1' + '/employment-kpi/failed-in-90/',
            getTimeToFill: environment.kpiEndpoint + '/v1' + '/employment-kpi/time-to-fill/',
            getCostPerHire: environment.kpiEndpoint + '/v1' + '/employment-kpi/cost-per-hire/',
            getHiresPerInterview:
                environment.kpiEndpoint + '/v1' + '/employment-kpi/hires-per-interview/',
            getAcceptPerOffer:
                environment.kpiEndpoint + '/v1' + '/employment-kpi/accept-per-offer/',
            getYield: environment.kpiEndpoint + '/v1' + '/employment-kpi/yield/',
        },
    },
};
