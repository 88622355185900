const appConstants = {
    APPROVALTYPES: {
        ADMIN_ROLES: 'admin roles',
        CADRES: 'cadre',
    },
    ROLES: {
        OWNER: 'owner',
        HRMANAGER: 'hrmanager',
        HROFFICER: 'hrofficer',
        ITOFFICER: 'itofficer',
        MANAGER: 'manager',
        MANGERSMANAGER: 'managersmanager',
    },
    MM: {
        id: 'managersmanager',
        name: 'mangers manager',
        type: 'managersmanager',
    },
    defaultRoles: [
        {
            id: 'manager',
            roleName: 'manager',
            type: 'manager',
        },
        {
            id: 'specific_person',
            roleName: 'specific person',
            person: 'person',
        },
    ],
    defaultPageOptions: [
        {
            key: '10',
            value: '10',
            selected: true,
        },
        {
            key: '20',
            value: '20',
        },
        {
            key: '50',
            value: '50',
        },
        {
            key: '100',
            value: '100',
        },
    ],
    defaultPeriodOptions: [
        {
            key: 'All',
            value: '',
            selected: true,
        },
        {
            key: 'Last 7 Days',
            value: '7',
        },
        {
            key: 'Last 14 days',
            value: '14',
        },
        {
            key: 'Last 30 days',
            value: '30',
        },
    ],
    loaderOptions: {
        path: './assets/motions/loader.json',
    },
};

export default appConstants;
