export function GetCheckboxesTruthyValues(checks: any, checkboxName: any) {
    const objectKeys: any[] = Object.keys(checks[checkboxName]);
    return objectKeys.filter((key) => {
        if (checks[checkboxName][key]) {
            return key;
        }
    });
}

export function QueryGenerator(values: any, position: string = 'pre'): string {
    let result = position === 'pre' ? '?' : '';
    Object.keys(values).forEach((el, i) => {
        if (values[el]) {
            result += `${!result || i === 0 ? '' : '&'}${el}=${values[el]}`;
        }
    });
    return result;
}

export function GenerateFileName(): string {
    return Date.now().toString();
}

export function GenerateDateList(
    min: number = new Date().getFullYear(),
    max?: number,
    count: number = 10
): number[] {
    max = max ? max : min + count;
    // console.log;
    let years = [];
    for (let i = min; i <= max; i++) {
        years.push(i);
    }
    return years;
}
