import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PAGINATOR } from 'src/app/interfaces/general';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
    @Input() paginatorSetup: PAGINATOR = {
        currentPage: 1,
        totalCount: 0,
        totalPages: 1,
    };
    @Output() paginateEvent = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    paginate(action: string) {
        const count =
            action === 'less'
                ? --this.paginatorSetup.currentPage
                : ++this.paginatorSetup.currentPage;
        this.paginateEvent.emit({ count });
    }
}
