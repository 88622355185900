import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import urlList from '../../../../services/service-list.json';

@Injectable()
export class SettingsService {
    private apiUrl = environment.recruitmentEndpoint;
    private myXalaryUrl = environment.myXalaryEndpoint;
    private dmsUrl = environment.dmsEndpoint;
    private lndUrl = environment.learningEndPoint;

    constructor(private http: HttpClient) {}

    public createChecklist(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.addChecklist;
        return this.http.post(url, payload);
    }

    public editChecklist(checklistID: any, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.editChecklist + '/' + checklistID;
        return this.http.patch(url, payload);
    }

    public getChecklist(type: string, queryParameters?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.fetchChecklist +
            '?type=' +
            type.toLowerCase() +
            `${queryParameters ? `&${queryParameters}` : '&page=1&limit=8'}`;
        return this.http.get(url);
    }

    public deleteChecklist(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.deleteChecklist;
        return this.http.delete(url, { body: payload });
    }

    // CHECKLIST ENDS

    //JOB ROLE
    public createJobRole(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.createJobRole;
        return this.http.post(url, payload);
    }
    public editJobRole(jobTemplateId: any, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.editJobRole + '/' + jobTemplateId;
        return this.http.put(url, payload);
    }
    public getSingleJobRole(templateID: string, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.fetchJobRole + '/' + templateID;
        return this.http.post(url, payload);
    }

    public getJobRoleById(templateID: string): Observable<any> {
        const url = this.apiUrl + urlList.settings.fetchSingleJobRole + '/' + templateID;
        return this.http.get(url);
    }
    public searchJobCategory(searchVal?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.searchJobCategory +
            (searchVal ? '?name=' + searchVal : '');
        return this.http.get(url);
    }
    public getJobTemplate(queryParameters?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.fetchJobRoles +
            `${queryParameters ? queryParameters : '?page=1&limit=8'}`;
        return this.http.get(url);
    }
    // EMAIL TEMPLATES

    public createEmailTemplate(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.addEmailTemplate;
        return this.http.post(url, payload);
    }

    public editEmailTemplate(EmailTemplateID: any, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.editEmailTemplate + '/' + EmailTemplateID;
        return this.http.patch(url, payload);
    }

    public getSingleEmailTemplate(templateID: string, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.fetchSingleEmailTemplate + '/' + templateID;
        return this.http.post(url, payload);
    }

    public searchEmailTemplate(params: string): Observable<any> {
        const url = this.apiUrl + urlList.settings.searchEmailTemplate + '/' + params;
        return this.http.get(url);
    }

    public getEmailTemplate(queryParameters?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.fetchEmailTemplate +
            `${queryParameters ? queryParameters : '?page=1&limit=8'}`;
        return this.http.get(url);
    }

    public deleteEmailTemplate(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.deleteEmailTemplate;
        return this.http.delete(url, { body: payload });
    }

    // SKILLS

    public createSkill(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.addSkill;
        return this.http.post(url, payload);
    }

    public editSkill(SkillID: any, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.editSkill + '/' + SkillID;
        return this.http.patch(url, payload);
    }

    public getSingleSkill(templateID: string, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.fetchSingleSkill + '/' + templateID;
        return this.http.post(url, payload);
    }

    public searchSkill(params: string): Observable<any> {
        const url = this.apiUrl + urlList.settings.searchSkill + '?search=' + params;
        return this.http.get(url);
    }

    public getSkills(queryParameters?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.fetchSkills +
            `${queryParameters ? queryParameters : '?page=1&limit=8'}`;
        return this.http.get(url);
    }

    public deleteSkill(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.deleteSkill;
        return this.http.delete(url, { body: payload });
    }

    // DEpartments
    public createDepartment(payload: any): Observable<any> {
        const url = this.myXalaryUrl + urlList.settings.addDepartment;
        return this.http.post(url, payload);
    }

    public getDepartments(): Observable<any> {
        const url = this.myXalaryUrl + urlList.settings.getDepartments;
        return this.http.get(url);
    }

    public searchDepartments(searchWord: string): Observable<any> {
        const url = this.myXalaryUrl + urlList.settings.getDepartments + '?search=' + searchWord;
        return this.http.get(url);
    }
    //COMPETENCYCATEGORY
    public createCompetencyCategory(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.addCompetencyCategory;
        return this.http.post(url, payload);
    }
    public editCompetencyCategory(competencyCategoryID: any, payload: any): Observable<any> {
        const url =
            this.apiUrl + urlList.settings.editCompetencyCategory + '/' + competencyCategoryID;
        return this.http.patch(url, payload);
    }
    public getSingleCompetencyCategory(competencyCategoryID: string): Observable<any> {
        const url =
            this.apiUrl + urlList.settings.getSingleCompetencyCategory + '/' + competencyCategoryID;
        return this.http.get(url);
    }
    public getCompetencyCategory(queryParameters?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.fetchCompetencyCategorys +
            `${queryParameters ? queryParameters : '?page=1&limit=8'}`;
        return this.http.get(url);
    }
    public deleteCompetencyCategory(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.deleteCompetencyCategory;
        return this.http.delete(url, { body: payload });
    }

    public createCompetencyLevel(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.addCompetencyLevel;
        return this.http.post(url, payload);
    }

    public editCompetencyLevel(competencyLevelID: any, payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.editCompetencyLevel + '/' + competencyLevelID;
        return this.http.patch(url, payload);
    }

    public getcompetencyLevels(queryParameters?: string): Observable<any> {
        const url =
            this.apiUrl +
            urlList.settings.fetchCompetencyLevels +
            `${queryParameters ? queryParameters : '?page=1&limit=8'}`;
        return this.http.get(url);
    }

    // SETTINGS API

    public getAllSettings(): Observable<any> {
        const url = this.apiUrl + urlList.settings.settings;
        return this.http.get(url);
    }

    public createDocument(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.settings;
        return this.http.post(url, payload);
    }

    public pushDataToSettings(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.settings;
        return this.http.post(url, payload);
    }

    public deleteSettingsData(id: any, type: string): Observable<any> {
        const url = this.apiUrl + urlList.settings.settings + '/' + id + `?requestType=${type}`;
        return this.http.delete(url);
    }

    public getDocumentTypes(): Observable<any> {
        const url = this.dmsUrl + 'docs/doc-type/dropdown';
        return this.http.get(url);
    }

    public getDocumentDms(): Observable<any> {
        const url = this.dmsUrl + 'docs';
        return this.http.get(url);
    }

    public updateTask(payload: any, id: string): Observable<any> {
        const url = this.apiUrl + urlList.settings.settings + `/${id}`;
        return this.http.patch(url, payload);
    }

    public updateHirePacket(payload: any, id: string): Observable<any> {
        const url = this.apiUrl + urlList.settings.settings + `/${id}`;
        return this.http.patch(url, payload);
    }

    public createTemplate(payload: any): Observable<any> {
        const url = this.apiUrl + urlList.settings.addTemplate;
        return this.http.post(url, payload);
    }

    public getCourses(): Observable<any> {
        const url = this.lndUrl + '/training/list';
        // https://lnd-test.myxalary.com/api/v1/training/list
        return this.http.get(url);
    }

    public getTraining(): Observable<any> {
        const url = this.lndUrl + '/trainingTemplates/list';
        return this.http.get(url);
    }

    public getAllTemplate(): Observable<any> {
        const url = this.apiUrl + urlList.settings.fetchAllTemplate;
        return this.http.get(url);
    }

    public submitFile(payload: any): Observable<any> {
        const url = this.lndUrl + '/files';
        return this.http.post(url, payload);
    }
}
