import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { environment } from 'src/environments/environment';
import * as fromStore from './app-data';
import { ErrorIntercept } from './interceptors/error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AuthenticatedComponent } from './modules/authentication/authenticated/authenticated.component';
import { ForgotPasswordComponent } from './modules/authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/authentication/login/login.component';
import { RegisterComponent } from './modules/authentication/register/register.component';
import { Chatv2Service } from './modules/chats-v2/services/chatv2.service';
import { ComponentsModule } from './modules/health/components/components.module';
import * as fromHealthStore from './modules/health/health-data';
import { HealthWrapperComponent } from './modules/health/health.component';
import { HealthService } from './modules/health/health.service';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { AppSocketService } from './shared/services/app-socket.service';
import { NotificationService } from './shared/services/notification.service';
import { EmployeeInterceptor } from './interceptors/employee.interceptor';
import { ApprovalWorkflowDashboardComponent } from './modules/settings/approval-workflow/dashboard/approval-workflow-dashboard.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        HealthWrapperComponent,
        AuthenticatedComponent,
        ForgotPasswordComponent,
        ApprovalWorkflowDashboardComponent,
    ],
    imports: [
        RouterModule,
        BrowserModule,
        SharedModule,
        AppRoutingModule,
        StoreModule.forRoot(fromStore.reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            autoPause: true,
        }),
        EffectsModule.forRoot([...fromStore.effects]),
        EffectsModule.forFeature([...fromHealthStore.effects]),
        StoreModule.forFeature(fromHealthStore.HEALTH, fromHealthStore.reducers),
        LayoutModule,
        ComponentsModule,
        BrowserAnimationsModule,
        NgMultiSelectDropDownModule.forRoot(),
    ],
    providers: [
        ...fromHealthStore.stores,
        ...fromHealthStore.dispatchers,
        Chatv2Service,
        HealthService,
        NgbActiveModal,
        AppSocketService,
        NotificationService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorIntercept,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: EmployeeInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
