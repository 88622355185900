<modal-cover title="Notification">
    <div
        infinite-scroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="10"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
        class="w-100 notify-cover d-flex flex-column"
    >
        <div *ngFor="let notify of notifications | async" class="clickable w-100">
            <div
                (click)="open(notify)"
                class="notify-list-item"
                [class.notView]="!notify.inAppViewed"
            >
                <div class="notify-title">{{ notify.inAppData.title }}</div>
                <div class="notify-title-holder">
                    <span class="notify-message">{{ notify.inAppData.message }} </span>
                    <span class="notify-time"> {{ notify.createdOn | date: 'dd/MM/YY h:mm' }}</span>
                </div>
            </div>
            <hr class="hr" />
        </div>
    </div>
</modal-cover>
